import { useEffect, useState } from 'react'

export const useProduction = () => {
  const [isProduction, setIsProduction] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [isDevelopment, setIsDevelopment] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hostname === 'localhost') {
        return setIsDevelopment(true)
      }

      if (window.location.hostname === 'www.avon.com.br') {
        return setIsProduction(true)
      }

      if (window.location.hostname.includes('preview-')) {
        return setIsPreview(true)
      }
    }
  }, [])

  return {
    isProduction,
    isDevelopment,
    isPreview,
  }
}
