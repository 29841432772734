export const EventPromoClick = (alt?: string | null, page?: string) => {
  if (!alt?.length) {
    return
  }

  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const promoClick = {
    event: 'promoClick',
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id: alt,
            name: alt,
            creative: alt,
            position: page,
          },
        ],
      },
    },
  }

  const hasItem = dataLayer.some(
    ({ event, ecommerce }) =>
      event === 'promoClick' && ecommerce.promoClick.promotions[0].id === alt
  )

  if (hasItem) {
    return
  }

  dataLayer.push(promoClick)

  return null
}
