import React from 'react'
import type { IconProps } from '@naturacosmeticos/natds-react'
import { Icon } from '@naturacosmeticos/natds-react'
import { useProfile } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import { useWishlist } from 'src/contexts/WishlistContext/useWishlist'

import { WishlistButtonContainer } from './styled'

interface Props {
  id: string
}

const WishlistButton = ({ id }: Props) => {
  const profile = useProfile()
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const { setIsLoginModalOpen } = useLoginModal()
  const { isInWishlist, removeFromWishlist, addToWishlist } = useWishlist()

  const isProductInWishlist = isInWishlist(id)

  const heartIcon: IconProps = isProductInWishlist
    ? { name: 'filled-action-love', color: 'primary' }
    : { name: 'outlined-action-love', color: 'highlight' }

  const handleWishlistToggle = () => {
    if (!isAuthenticated) {
      return setIsLoginModalOpen(true)
    }

    if (isProductInWishlist) {
      return removeFromWishlist(id)
    }

    return addToWishlist(id)
  }

  return (
    <WishlistButtonContainer onClick={handleWishlistToggle}>
      <Icon {...heartIcon} size="standard" />
    </WishlistButtonContainer>
  )
}

export const WishlistButtonFallback = () => {
  return (
    <WishlistButtonContainer css={{ cursor: 'not-allowed' }}>
      <Icon name="outlined-action-love" size="standard" color="highlight" />
    </WishlistButtonContainer>
  )
}

export default WishlistButton
