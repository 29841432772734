import React from 'react'
import { hasCluster } from 'src/utils/hasCluster'
import { CustomBadge } from 'src/components/common/CustomBadge'

import { BadgesContainer } from './styled'

interface Props {
  price: number
  productClusters:
    | Array<{
        id: string
        name: string
      }>
    | undefined
}

export const Badges = ({ productClusters, price }: Props) => {
  const hasFreeShippingBadge = price >= 220.0

  const hasOfferBadge = hasCluster({ productClusters, selectedCluster: '1729' })

  const hasRepresentativeBadge = hasCluster({
    productClusters,
    selectedCluster: '1795',
  })

  const hasTrataMakeBadge = hasCluster({
    productClusters,
    selectedCluster: '1807',
  })

  const hasReleaseBadge = hasCluster({
    productClusters,
    selectedCluster: '1735',
  })

  return (
    <BadgesContainer>
      {hasReleaseBadge && <CustomBadge text="Lançamento" />}
      {hasOfferBadge && <CustomBadge text="Oferta" />}
      {hasRepresentativeBadge && <CustomBadge text="Exclusivo Rep" />}
      {hasTrataMakeBadge && <CustomBadge text="Cupom TRATAMAKE20" />}
      {hasFreeShippingBadge && <CustomBadge text="Frete Grátis" />}
    </BadgesContainer>
  )
}
