import React from 'react'
import { useDiscount } from '@vtex/gatsby-theme-store'
import { Icon } from '@naturacosmeticos/natds-react'
import { formatPrice } from 'src/utils/formatPrice'

import { PriceContainer } from './styled'

interface Props {
  offer: {
    listPrice: number
    price: number
    maxInstallments: Array<{
      value: number
      numberOfInstallments: number
    }>
  }
  isAvailable: boolean
}

const PriceInfo = ({ offer, isAvailable }: Props) => {
  const { price, listPrice, maxInstallments } = offer

  const discountPercentage = useDiscount(offer)

  if (!isAvailable) {
    return (
      <PriceContainer isUnavailable>
        <div className="unavailable-product">
          <Icon name="outlined-alert-warning" color="warning" />
          <p>Produto Indisponível</p>
        </div>
      </PriceContainer>
    )
  }

  if (maxInstallments.length <= 0) {
    return null
  }

  const hasDiscount = price < listPrice
  const showInstallments = maxInstallments[0].numberOfInstallments > 1

  const listPriceFormatted = hasDiscount && formatPrice(listPrice)
  const priceFormatted = formatPrice(price)

  const formattedInstallmentsPrice =
    showInstallments &&
    `ou ${maxInstallments[0].numberOfInstallments}x ${formatPrice(
      maxInstallments[0].value
    )}`

  return (
    <PriceContainer>
      {listPriceFormatted && (
        <div className="list-price">{listPriceFormatted}</div>
      )}

      <div className="price">
        {priceFormatted}
        {hasDiscount && (
          <p className="discount-percentage">-{discountPercentage}%</p>
        )}
      </div>

      {showInstallments && (
        <div className="installments">{formattedInstallmentsPrice}</div>
      )}
    </PriceContainer>
  )
}

export default PriceInfo
