import React, { useMemo, memo } from 'react'
import { Rating } from '@naturacosmeticos/natds-react'
import { Link } from 'gatsby'
import type { Product } from 'src/typings'
import loadable from '@loadable/component'
import { useThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import imageConf from 'src/images/config'
import { EventProductClick } from 'src/components/common/EventProductClick'
import { EventPromoClick } from 'src/components/common/EventPromoClick'
import { SuspenseSSR } from '@vtex/store-ui'
import { useReview } from 'src/hooks/useReview'

import PriceInfo from './PriceInfo'
import { WishlistButtonFallback } from './WishlistButton'
import {
  ImageContainer,
  ProductInfoContainer,
  ProductNameContainer,
  ProductShelfContainer,
  WishlistButtonWrapper,
} from './styled'
import SkuColors from './SkuColors'
import { Badges } from './Badges'
import ButtonFallback from './BuyButton/ButtonFallback'

const BuyButton = loadable(() => import('./BuyButton'))
const WishlistButton = loadable(() => import('./WishlistButton'))

interface Props {
  product: Product
  title?: string
  index?: number
}

const ProductCard = ({ product, title, index }: Props) => {
  const {
    brand,
    items,
    linkText,
    productName,
    id,
    productClusters,
    categoryTree,
  } = product

  const productLink = `/${linkText}/p`

  const initialSku =
    useMemo(() => {
      if (!items) {
        return null
      }

      const availableSkus = items.filter((sku) => {
        if (
          !sku?.sellers ||
          !sku?.sellers[0]?.commercialOffer?.availableQuantity
        ) {
          return false
        }

        if (sku.sellers[0].commercialOffer.availableQuantity > 0) {
          return sku
        }

        return false
      })

      let [cheapestPriceProduct] = availableSkus

      const [cheapestSku] = availableSkus.map((sku) => {
        if (!sku) {
          return availableSkus[0]
        }

        if (
          sku.sellers?.[0]?.commercialOffer?.price &&
          cheapestPriceProduct?.sellers?.[0]?.commercialOffer?.price &&
          sku.sellers[0].commercialOffer.price <
            cheapestPriceProduct?.sellers?.[0]?.commercialOffer.price
        ) {
          cheapestPriceProduct = sku

          return sku
        }

        return sku
      })

      return cheapestSku
    }, [items]) ?? items[0]

  const image = useThumborImageData({
    ...imageConf['product.summary'],
    baseUrl: initialSku.images[0]?.imageUrl as string,
  })

  const hasVariations =
    items.length > 1 &&
    initialSku.variations &&
    initialSku.variations.length > 0

  const isAvailable =
    initialSku.sellers[0].commercialOffer.availableQuantity > 0 &&
    initialSku.sellers[0].commercialOffer.maxInstallments.length > 0

  const { average } = useReview(id)
  const formattedAverage = average.toFixed(1).replace('.', ',')

  const sendEventsOnClick = () => {
    EventPromoClick(productName, 'Imagem Vitrine de Produtos')

    EventProductClick({
      id,
      name: productName,
      price: initialSku.sellers[0].commercialOffer.price,
      brand,
      category: categoryTree,
      variant: initialSku.itemId,
      list: title,
      position: index,
    })
  }

  return (
    <ProductShelfContainer>
      <ImageContainer>
        <WishlistButtonWrapper>
          <Badges
            productClusters={productClusters}
            price={initialSku.sellers[0].commercialOffer.price}
          />

          <SuspenseSSR fallback={<WishlistButtonFallback />}>
            <WishlistButton id={id} />
          </SuspenseSSR>
        </WishlistButtonWrapper>

        <Link
          to={productLink}
          title={productName ?? brand}
          onClick={sendEventsOnClick}
        >
          <img
            alt={productName ?? brand}
            title={productName ?? brand}
            width={image.width}
            height={image.height}
            {...image.images.fallback}
          />
        </Link>

        {hasVariations && <SkuColors skus={items} />}
      </ImageContainer>

      <ProductInfoContainer>
        <ProductNameContainer>
          <div>
            <p>{brand}</p>

            <Rating
              variant="counter"
              ariaLabel="rating star"
              size="small"
              label={formattedAverage}
              className="rating-star"
            />
          </div>

          <h3>{productName}</h3>
        </ProductNameContainer>

        <PriceInfo
          offer={initialSku?.sellers[0].commercialOffer}
          isAvailable={isAvailable}
        />

        <BuyButton
          fallback={<ButtonFallback />}
          sku={initialSku}
          productId={id}
          brand={brand}
          categoryTree={categoryTree}
          qty={1}
          productLink={productLink}
          hasVariations={!!hasVariations}
          isAvailable={isAvailable}
          title={title}
          index={index}
        />
      </ProductInfoContainer>
    </ProductShelfContainer>
  )
}

export default memo(ProductCard)
