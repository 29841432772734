import { useCallback, useEffect, useState } from 'react'

import { useProduction } from './useProduction'

export type Average = 0 | 1 | 2 | 3 | 4 | 5
export type ProductReviewsType = Array<{
  id: string
  productId: string
  rating: Average
  title: string
  text: string
  reviewerName: string
  approved: boolean
}>

export const useReview = (productId: string) => {
  const [average, setAverage] = useState<Average>()
  const [totalRating, setTotalRating] = useState<number>()
  const [productReviews, setProductReviews] = useState<ProductReviewsType>()

  const url = 'https://avongroup.myvtex.com/_v/public/graphql/v1'

  const getAverage = useCallback(() => {
    const myHeaders = new Headers()

    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append(
      'Cookie',
      'VtexWorkspace=master%3A-; VtexWorkspace=master%3A-'
    )

    const averageRatingByProductId = JSON.stringify({
      query: `{\r\n  averageRatingByProductId(productId: "${productId}") {
        average
      }\r\n}`,
    })

    fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: averageRatingByProductId,
      cache: 'force-cache',
    })
      .then((response) => response.json())
      .then(({ data }) =>
        setAverage(data?.averageRatingByProductId.average ?? 0)
      )
      .catch((error) => {
        setAverage(0)
        throw new Error('Failed to fetch [getAverage]', error)
      })
  }, [productId])

  const getProductReviews = useCallback(() => {
    const myHeaders = new Headers()

    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append(
      'Cookie',
      'VtexWorkspace=master%3A-; VtexWorkspace=master%3A-'
    )

    const reviewsByProductId = JSON.stringify({
      query: `{\r\n  reviewsByProductId(productId: "${productId}") {
        range {
          total
        }

        reviews: data {
          id
          productId
          rating
          title
          text
          reviewerName
          approved

        }
      }\r\n}`,
    })

    fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: reviewsByProductId,
      cache: 'force-cache',
    })
      .then((response) => response.json())
      .then(({ data }) => {
        setProductReviews(data.reviewsByProductId.reviews)
        setTotalRating(data.reviewsByProductId.range.total ?? 0)
      })
      .catch((error) => {
        throw new Error('Failed to fetch [getProductReviews]', error)
      })
  }, [productId])

  const { isProduction } = useProduction()

  useEffect(() => {
    if (isProduction) {
      if (typeof average === 'undefined') {
        getAverage()
      }

      if (
        typeof totalRating === 'undefined' ||
        typeof productReviews === 'undefined'
      ) {
        getProductReviews()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProduction])

  return {
    average: (average as Average) ?? 0,
    totalRating: totalRating ?? 0,
    productReviews,
  }
}
