import { styled } from 'src/gatsby-theme-stitches/config'

export const BadgeContainer = styled('div', {
  backgroundColor: '$highlight',
  color: '$onPrimary',
  borderRadius: '50px',
  height: '1rem',
  fontFamily: '$roboto',
  fontSize: '.75rem',
  letterSpacing: '0.032rem',
  px: '.25rem',
  minWidth: '5rem',
  display: 'grid',
  placeItems: 'center',
})
