interface Props {
  productClusters:
    | Array<{
        id: string
        name: string
      }>
    | undefined
  selectedCluster: string
}

export const hasCluster = ({ productClusters, selectedCluster }: Props) => {
  return productClusters
    ? productClusters.filter((cluster) => cluster?.id === selectedCluster)
        .length > 0
    : false
}
