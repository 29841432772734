/* eslint-disable no-console */

interface Props {
  id: string
  name: string
  price: number
  brand: string
  category: Array<{ name: string; href: string }>
  variant: string
  list?: string
  position?: number
}

export const EventProductClick = ({
  id,
  name,
  price,
  brand,
  category,
  variant,
  list,
  position,
}: Props) => {
  if (!id) {
    return
  }

  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const productClick = {
    event: 'productClick',
    ecommerce: {
      click: {
        products: [
          {
            id,
            name,
            price,
            brand,
            category,
            variant,
            list,
            position,
          },
        ],
      },
    },
  }

  dataLayer.push(productClick)

  return null
}
