import React, { useEffect, useMemo, useState } from 'react'
import type { KeenSliderOptions } from 'keen-slider/react'
import { useKeenSlider } from 'keen-slider/react'
import type { Product } from 'src/typings'
import { useColabStore } from 'src/hooks/useColabStore'
import { useQuery } from '@vtex/gatsby-theme-store'
import { gql } from '@vtex/gatsby-plugin-graphql'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'
import { Icon } from '@naturacosmeticos/natds-react'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import { ProductSliderContainer } from './styled'
import type {
  ProductSliderQueryQuery,
  ProductSliderQueryQueryVariables,
} from './__generated__/ProductSliderQuery.graphql'
import { ProductSliderQuery } from './__generated__/ProductSliderQuery.graphql'
import Pages from './Pages'

interface Props {
  products?: Product[]
  searchParams?: {
    collection: string
    from: number
    to: number
    hideUnavailableItems: boolean
    orderBy: string
  }
  shelfImage?: Array<{ srcSet: string; link: string }> | undefined
  title?: string
}

const ProductSlider = (props: Props) => {
  const [products, setProducts] = useState(props.products ?? [])
  const [currentSlide, setCurrentSlide] = useState(0)

  const sliderOptions: KeenSliderOptions = useMemo(() => {
    return {
      initial: 0,
      breakpoints: {
        '(max-width: 767px)': {
          slides: { perView: 1, spacing: 16 },
        },
        '(min-width: 768px)': {
          slides: { perView: 1, spacing: 16 },
        },
        '(min-width: 1024px)': {
          slides: { perView: 1, spacing: 24 },
        },
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
    }
  }, [])

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(sliderOptions)

  const { searchParams, shelfImage } = props

  const { isUserColab } = useColabStore()
  const { isRepresentativeSelected } = useRepresentative()
  const salesChannel = isUserColab ? '3' : isRepresentativeSelected ? '2' : '1'

  const variables = {
    ...searchParams,
    salesChannel,
  }

  const { data } = useQuery<
    ProductSliderQueryQuery,
    ProductSliderQueryQueryVariables
  >({
    ...ProductSliderQuery,
    variables,
  })

  useMemo(() => {
    if (data !== undefined) {
      setProducts(data.vtex.products as unknown as Product[])
    }
  }, [data])

  useEffect(() => {
    instanceRef.current?.update(sliderOptions)
  }, [instanceRef, products, sliderOptions])

  const { isMobile } = useDeviceDetect()

  return (
    <ProductSliderContainer className="navigation-wrapper">
      <div ref={sliderRef} className="keen-slider">
        <Pages
          data={
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [...(shelfImage ? [shelfImage] : []), ...products] as any
          }
          pageSize={isMobile ? 2 : 4}
        />
      </div>

      <div className="slider-arrow">
        <button
          aria-label="Previous"
          onClick={() => instanceRef.current?.prev()}
          disabled={currentSlide === 0}
        >
          <Icon
            name="outlined-navigation-directionright"
            color="mediumEmphasis"
          />
        </button>

        <button
          aria-label="Next"
          onClick={() => instanceRef.current?.next()}
          disabled={
            instanceRef.current
              ? currentSlide ===
                instanceRef.current.track.details?.slides.length - 1
              : false
          }
        >
          <Icon
            name="outlined-navigation-directionleft"
            color="mediumEmphasis"
          />
        </button>
      </div>

      <div className="dots">
        {instanceRef.current &&
          [
            ...Array(
              instanceRef.current.track.details
                ? instanceRef.current.track.details.maxIdx + 1
                : 1
            ).keys(),
          ].map((idx) => (
            <button
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx)
              }}
              aria-label={`Página ${idx + 1}`}
              className={`dot${currentSlide === idx ? ' active' : ''}`}
            />
          ))}
      </div>
    </ProductSliderContainer>
  )
}

export const query = gql`
  query ProductSliderQuery(
    $from: Int!
    $to: Int!
    $collection: String!
    $orderBy: String
    $hideUnavailableItems: Boolean!
    $salesChannel: String!
  ) {
    vtex {
      products(
        from: $from
        to: $to
        collection: $collection
        orderBy: $orderBy
        hideUnavailableItems: $hideUnavailableItems
        salesChannel: $salesChannel
      ) {
        ...ProductSummary_product
      }
    }
  }
`

export default ProductSlider
