import React from 'react'
import type { ProductItem } from 'src/typings'
import { Icon } from '@naturacosmeticos/natds-react'

import { SkuContainer } from './styled'

interface Props {
  skus: ProductItem[]
}

const SkuColors = ({ skus }: Props) => {
  const variationsNameImage = skus.map((sku) => {
    const formattedVariationName = sku.name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-')
      .replace(/-+/g, '-')

    return `https://${process.env.GATSBY_VTEX_IO_WORKSPACE}--${process.env.GATSBY_STORE_ID}.vtexassets.com/arquivos/${formattedVariationName}.jpg`
  })

  return (
    <SkuContainer>
      <div>
        {variationsNameImage &&
          skus
            .slice(0, 3)
            .map((sku, index) => (
              <img
                key={sku.itemId}
                src={variationsNameImage[index]}
                alt={sku.variations?.[0]?.values[0] ?? sku.name}
              />
            ))}

        {skus.length > 3 && <Icon name="filled-action-add" size="small" />}
      </div>
    </SkuContainer>
  )
}

export default SkuColors
