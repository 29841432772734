/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import type { Product } from 'src/typings'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'

import ProductCard from '../ProductCard'
import { PageContainer } from './styled'

type PagesData = Array<
  | Array<{
      srcSet: string
      link: string
    }>
  | Product[]
>

interface Props {
  data: PagesData
  pageSize: number
}

const splitPages = (arr: PagesData, chunkSize: number) => {
  const res = []

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)

    res.push(chunk)
  }

  return res
}

const instanceOfProduct = (item: any): item is Product => {
  return 'id' in item
}

const Pages = ({ data, pageSize }: Props) => {
  const pages = splitPages(data, pageSize)

  return (
    <>
      {pages.map((page, index) => (
        <PageContainer key={index} className="keen-slider__slide">
          {page.map((item) => {
            const isProduct = instanceOfProduct(item)

            if (isProduct) {
              const product = item

              return <ProductCard product={product} key={product.id} />
            }

            const shelfImage = item

            return (
              <a href={shelfImage[0].link} key={shelfImage[0].link}>
                <ResponsiveImage
                  alt=""
                  desktop={shelfImage[0].srcSet}
                  mobile={shelfImage[1].srcSet}
                  lazy
                />
              </a>
            )
          })}
        </PageContainer>
      ))}
    </>
  )
}

export default Pages
