import { styled } from 'src/gatsby-theme-stitches/config'

export const ProductShelfContainer = styled('div', {
  backgroundColor: '$onPrimary',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: '1rem',
  fontFamily: '$montserrat',
})

export const WishlistButtonWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  position: 'absolute',
})

export const WishlistButtonContainer = styled('div', {
  display: 'flex',
  marginLeft: 'auto',
  cursor: 'pointer',
})

export const BadgesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
})

export const ImageContainer = styled('div', {
  position: 'relative',

  a: {
    display: 'grid',
    placeItems: 'center',

    img: {
      width: '6.125rem',
      height: '7.3125rem',
      objectFit: 'cover',

      '@lg': {
        width: '11.875rem',
        height: '14.0625rem',
        objectFit: 'contain',
      },
    },
  },
})

export const SkuContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  position: 'absolute',
  bottom: '.25rem',

  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
    padding: '.25rem',
    borderRadius: '4px',
    backgroundColor: '#e5e5e570',

    i: {
      fontSize: '1rem',
      height: '1rem',
    },
  },

  img: {
    width: '1rem',
    height: '1.5rem',
    borderRadius: '2px',
  },
})

export const ProductInfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '13.5rem',

  '@sm': {
    height: '14.5rem',
  },
})

export const ProductNameContainer = styled('div', {
  h3: {
    lineHeight: '$medium',
    color: '$black',
    fontWeight: '$regular',
    fontSize: '.875rem',
    height: '3.9375rem',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    'line-clamp': 3,
    '-webkit-box-orient': 'vertical',

    '@lg': {
      fontSize: '$3',
      height: '3rem',
      '-webkit-line-clamp': 2,
      'line-clamp': 2,
    },
  },

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '$mediumEmphasis',

    p: {
      fontSize: '.875rem',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    label: {
      fontFamily: '$montserrat',
    },
  },

  '.rating-star': {
    div: {
      height: '19px',
    },
  },
})

export const PriceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: '0.25rem',
  height: '4.25rem',
  my: '1rem',

  '.list-price': {
    fontSize: '.75rem',
    color: '$mediumEmphasis',
    textDecoration: 'line-through',
  },

  '.price': {
    color: '$black',
    fontSize: '$3',
    lineHeight: '$medium',
    fontWeight: '$medium',

    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },

  '.discount-percentage': {
    fontFamily: '$roboto',
    fontSize: '.75rem',
    fontWeight: '$regular',
    color: '$white',
    backgroundColor: '$primary',
    borderRadius: '8px',
    width: '2.5rem',
    height: 'auto',
    display: 'grid',
    placeItems: 'center',
  },

  '.installments': {
    color: '$mediumEmphasis',
    fontSize: '.75rem',
  },

  '.unavailable-product': {
    display: 'flex',
    gap: '0.5rem',
    color: '$warning',
  },

  variants: {
    isUnavailable: {
      true: {
        justifyContent: 'center',
      },
    },
  },
})

export const BuyButtonContainer = styled('div', {
  span: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '@sm': {
      fontSize: '.75rem',
    },

    '@media (max-width: 340px)': {
      fontSize: '.625rem',
    },
  },

  i: {
    '@media (max-width: 350px)': {
      fontSize: '1.25rem',
    },
  },

  variants: {
    isSeeMoreButton: {
      true: {
        button: {
          borderColor: '$colors$onBackground !important',
        },
      },
    },

    isFallbackButton: {
      true: {
        button: {
          cursor: 'not-allowed',
        },
      },
    },
  },
})
