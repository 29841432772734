import React from 'react'
import { Button } from '@naturacosmeticos/natds-react'

import { BuyButtonContainer } from '../styled'

export default function ButtonFallback() {
  return (
    <BuyButtonContainer isFallbackButton>
      <Button fullWidth variant="outlined" onClick={() => null}>
        CARREGANDO
      </Button>
    </BuyButtonContainer>
  )
}
