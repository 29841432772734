import React from 'react'

import { BadgeContainer } from './styled'

interface Props {
  text: string
}

export const CustomBadge = ({ text }: Props) => {
  return <BadgeContainer>{text}</BadgeContainer>
}
