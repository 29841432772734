import { styled } from 'src/gatsby-theme-stitches/config'

export const ProductSliderContainer = styled('div', {
  position: 'relative',

  '.dots': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    gap: '1rem',

    '.dot': {
      border: 'none',
      size: '0.5rem',
      backgroundColor: '$lowEmphasis',
      cursor: 'pointer',
      borderRadius: '50%',

      '&.active': {
        backgroundColor: '$primary',
      },
    },
  },

  '.keen-slider__slide': {
    minWidth: '18.875rem',
    maxWidth: '18.875rem',

    picture: {
      '@sm': {
        height: '100%',
      },

      img: {
        maxHeight: '29.5625rem',
        objectFit: 'none',

        '@sm': {
          maxHeight: '23.8125rem',
          objectFit: 'cover',
        },
      },
    },
  },
})

export const PageContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',

  '@sm': {
    gap: '1rem',
  },

  '> div, > a': {
    width: '16.75rem',

    '@sm': {
      width: '50%',
    },
  },
})
